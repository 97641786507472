.template-cart .main>.container>.inner {
    padding: 0px;
}

ul.drop__down {
    display: none;
  position: absolute;
}

.menu--desktop .menu__item .drop__down.four__column {
	display: none;
	position: absolute;
	background: #fff;
	z-index: 9999;
	box-sizing: border-box !important;
	right: 20px;
	left: 180px;
	box-shadow: 0 8px 13px -2px #A7A7A7;
	padding: 20px ;
	border: 1px solid #ddd;
}
ul.submenu li {
    width: 100%;
}
.menu--desktop .menu__item .drop__down.one__column {
    display: none;
    position: absolute;
    background: #fff;
    z-index: 10;
  box-sizing: border-box !important;
    
  box-shadow: 0 8px 13px -2px #A7A7A7;
  padding: 20px ;
      border: 1px solid #ddd;
}

form.search {
    text-align: right;
}

input.srch {
    padding-right: 0px;
    width: 39px;
}

.menu--desktop .menu__item {
    position: static !important;
  padding-bottom: 10px;
}

.four__column > li {
    width: 25%;
    float: left;
}

h4.column-title {
    border-bottom: 1px solid #ddd;
    max-width: 90%;
    font-weight: 600;
    padding-bottom: 7px;
    margin-bottom: 7px;
    letter-spacing: 1px;
    color: #616161;
}

ul.sub-menu {
    list-style: none;
}

ul.sub-menu li a {
    font-weight: normal;
    line-height: 35px;
    font-size: 12px;
  display:block;
}

ul.sub-menu li:hover a{
color: #3A7EAF;
}

.menu--desktop .menu__item > a:before , .menu--desktop .menu__item > a:before{
  content: '';display: block;width: 8px;height: 8px;display: block;
  border-top: 1px solid #ddd;border-right: 1px solid #ddd; -webkit-transform: rotate(-45deg) !important;transform: rotate(-45deg) !important;position: absolute;bottom: 0px;z-index: 10000;background: #fff;left: 50%;margin-left: -4px;
  opacity: 0;
  transition: all 1s;
}
.menu--desktop .menu__item:hover > a:before , .menu--desktop .menu__item.sfHover > a:before{
  opacity: 1;
  transition: all 1s;
}

.menu--desktop .menu__item {
    position: initial !important;
    padding-bottom: 0px;
    margin-bottom: 0px;
    line-height: 40px;
}

.menu--desktop .menu__link {
    position: relative;
}

.slick-prev:before {content: '' !important;display: block;border-top: 3px solid #000;border-right: 3px solid #000;height: 15px;width: 15px; -webkit-transform: rotate(-45deg);transform: rotate(-45deg);}


.slick-next:before {content: '' !important;display: block;border-bottom: 3px solid #000;border-left: 3px solid #000;height: 15px;width: 15px; -webkit-transform: rotate(-45deg);transform: rotate(-45deg);}


button.slick-prev.slick-arrow{
    left: 38px;
}

button.slick-next.slick-arrow {
    left: 38px;
    right: auto !Important;
    position: absolute;
    top: auto;
      bottom: -42px;
}


select[name=quantity] {
    background: transparent;
    -webkit-appearance: inherit !important;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGAgMAAACdogfbAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAADFBMVEVLS0sRERHDw8P///9wpy+CAAAAAWJLR0QDEQxM8gAAAAlwSFlzAAALEgAACxIB0t1+/AAAABtJREFUCNdjEHVgmJrAcNSA4ekGhg8fGH59AAA0IAefXwhQTAAAAABJRU5ErkJggg==');
    background-repeat: no-repeat;
    background-position: 90%;
    padding-left: 15px !important;
}


.related-stock {
    margin-bottom: 15px;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
}

h3.shopconfidence {
    font-size: 16px;
    margin-top: 20px;
    font-weight: bold;
    color: #616161;
    margin-bottom: 23px;
}


.slick-slide {
    padding: 0 2px;
    box-sizing: border-box;
}

section.footer__module h3.ftrhdng {
    font-size: 18px;
    text-transform: uppercase;
    text-transform: uppercase;
    font-weight: bold;
}

li.ftrfirstcolumnlistspacing {
    display: block;
    width: 100%;
}

h3.whatshappening {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
}

.footer__row .footer__module:first-child {
    width: 320px;
}

button.button.button--full.button--primary:hover {

    opacity: 0.8;
}


li.share-buttons__item a svg {
    font-size: 17px;
}



.product__tabs.clearfix {
    padding-top: 50px;
}

.product__tab-title {
    font-size: 16px;
}

.cart__total-amount {font-family: 'Open Sans';}


span.cart-item__line-price {
    line-height: 30px;
}

span.cart-item__unit-price {
    line-height: 30px;
}

input.button.cart-button {
    margin-bottom: 15px;
}

.marketing-module__column{
width: 49.25%;
}

section.marketing-module.container {
    padding: 0 1.25rem;
}

section.index-module.index-module__free-text-1 {
    padding-bottom: 30px;
}

section.index-module.index-module__blog {
    padding: 30px 0;
}

h3.article__title a {
    font-family: Playfair Display;
    font-size: 1.25rem;
}

h2.index-module__title {
    font-family: Playfair Display;
    margin-bottom: 20px;
}

section.index-module.index-module__instagram {
    padding-top: 10px;
}

h4.intext-module_title {
    font-size: 25px;
    font-weight: bold;
    color: #000d41;
    margin-bottom: 0px;
    font-family: arial;
    line-height: 38px;
}

.index-module__instagram h2.index-module__title {
    font-size: 55px;
    line-height: 61px;
    font-family: arial;
    font-weight: bold;
    color: #000d41;
}

section.index-module.index-module__free-text-2 {
    padding-top: 29px;
}

.index-module__free-text-2 h2.index-module__title {
    text-align: center;
    font-size: 33px;
    letter-spacing: 3px;
}

.index-module__instagram p {
    font-size: 17px;
    margin-bottom: 20px;
}

.index-module__instagram p a img {
    vertical-align: middle;
    padding: 0 0 0 10px;
    margin-bottom: 4px;
}

p.whyserbags {text-align: center;     margin-bottom: 20px;}

p.whyserbags span{
  line-height: 20px;
  display: inline-block;
    padding-left: 25px;
  padding-right: 25px;
  font-family: 'Open sans';
    font-weight: 300;
    font-size: 15px;
    font-weight: bold;
}

span.greentick {
  background: url('//cdn.shopify.com/s/files/1/1221/2828/t/2/assets/greentick.png?14254271854047337842') left no-repeat;
  color: #20C688;
}

span.bluetick {
  background: url('//cdn.shopify.com/s/files/1/1221/2828/t/2/assets/bluetick.png?14254271854047337842') left no-repeat;
  color: #02B6D1;
}

span.redtick {
  background: url('//cdn.shopify.com/s/files/1/1221/2828/t/2/assets/redtick.png?14254271854047337842') left no-repeat;
  color: #D91B35;
}


.filter-inner select {
    -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.securetransaction {
    padding-top: 30px;
  padding-left: 20px;
    height: 213px;
    background-color: #F2EEEF;
}


.securetransaction p {
    padding-left: 52px;
    line-height: 12px;
}

.securetransaction p small {
    display: block;
    line-height: 17px;
    font-size: 11px; 
    margin-bottom: 36px;
}



.securetransaction .icone1{
  background: url('//serbags.myshopify.com/cdn/shop/t/23/assets/icons_03.png?v=164900575733266190441461213945') left no-repeat;
}

.securetransaction .icone2{
  background: url('//serbags.myshopify.com/cdn/shop/t/23/assets/icons_06.png?v=102733397792676296721461213945') left no-repeat;
}

.securetransaction .icone3{
  background: url('//serbags.myshopify.com/cdn/shop/t/23/assets/icons_08.png?v=131933114888055784261461213945') left no-repeat;
}

button.srch {
    background-color: #bc3217;
    background-image: linear-gradient(to bottom, #bd3317, #a02400);
    border-color: #a92906;
    border-radius: 3px;
      width: 39px;
  height: 34px;
  vertical-align: top;
}

input.hdrsrch {
    vertical-align: top;
    margin-top: 0px;
}

.header__logo a.header__link {
    display: block;
    height: 135px;
	text-align:center;
}

button.srch svg {
    color: #fff;
    font-size: 18px;
    margin-top: 2px;
}

button.srch:hover {
 background-image: linear-gradient(to bottom, #a02400, #bd3317);
}

ul.sf-menu li.menu__item.sfHover> ul.drop__down {
    display: inheriet !important;
}


ul.sf-menu li ul.sub-menu {
    display: block !important;
}

.cart_savings span {
    width: auto;
    height: 11px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAALCAMAAABMIo1MAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAOVBMVEUvLy+9vb3////p6elJSUlFRUVHR0dMTEx4eHhAQEDLy8vw8PCHh4fOzs6kpKRsbGzh4eHk5ORCQkJ3gOriAAAAAWJLR0QCZgt8ZAAAAAlwSFlzAAALEgAACxIB0t1+/AAAAEpJREFUCNdjYGBkQgHMLAwgwMrEhE2cjQmrOKYwEzM7mjAHJxdUHFmYm4GBB8zgRVXNxy8AogRZsZkNFMUiDBJlEMIqyoDuS4goADanAqseCwdjAAAAAElFTkSuQmCC');
    display: inline-block;
    padding-left: 30px;
    background-repeat: no-repeat;
    line-height: 11px;
}

p.cart_savings {
    text-align: center;
    border-bottom: 1px solid #4B4B4B;
    padding-bottom: 15px;
}

ul.drop__down ul, ul.drop__down li{
display:block !important;
  opacity: 1 !important;
}

.quick-shop__slideshow {
    width: 100% !Important;
    max-width: 100%;
    margin: 0px !Important;
}

.quick-shop .slick-track {
    width: 100% !Important;
}

.quick-shp .slick-list {
    width: 100%;
}

.quick-shop .slick-slide {
    width: 100%!important;
}

.quick-shop .slick-slide img {
    margin: 0 auto;
}
.container.mobile_content_blog {
    display: block;
}
.mobile_filter {
    display: none;
}
.desktop_filter {
    display: block;
}
section.index-module.extra_remove_mobile_text.index-module__free-text-2 {
    display: block;
}
ul { list-style-type: none; }

a {
	  text-decoration: none;
}

.accordion {
  width: 100%;
  max-width: 100%;
  margin: 0;
  background: #FFF;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.accordion li {
    float: left;
    width: 50%;
    border: 1px solid #e5e5e5;
    border-left: none;
}
.accordion .link {
  cursor: pointer;
  display: block;
  padding: 15px 15px 15px 42px;
  color: #4D4D4D;
  font-size: 14px;
  font-weight: 500;
  border-bottom: none;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.accordion li:last-child .link { border-bottom: 0; }

.accordion li i {
  position: absolute;
  top: 16px;
  left: 12px;
  font-size: 18px;
  color: #595959;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.accordion li i.fa-chevron-down {
  right: 12px;
  left: auto;
  font-size: 16px;
}

.accordion li.open .link { color: #686868; }

.accordion li.open i { color: #686868; }

.accordion li.open i.fa-chevron-down {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.submenu {
	display: none;
	background: #fdfdfd;
	font-size: 14px;
	position: absolute;
	width: 101%;
	z-index: 99;
	left:0;
}
.submenu li { border-bottom: 1px solid #4b4a5e; }

.submenu a {
  display: block;
  text-decoration: none;
  color: #000;
  padding: 0;
  padding-left: 0;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  background:#fdfdfd !important;
}


.submenu a:hover {
  background: #b63b4d;
  color: #000;
}
i.fa.fa-database {
    display: none;
}
.submenu li {
    margin-bottom: -1px;
    padding: 17px 0 15px 43px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
}


.mobile-show{
display:none;
}
	.desktop-show{
display:block;
}
@media all and (max-width:768px){
	.mobile-show{
display:block;
}
.product__tabs.clearfix {
    padding-top: 20px;
}
.chk-out{
	background-size:100%;
}
	.desktop-show{
display:none;
}

img.header__logo-image {
    margin-left: 40px;
    max-width: 156px;
	max-height:300px;
}
section.index-module.index-module__blog {
    padding: 0px 0px;
}
section.index-module.index-module__free-text-1 {
    padding-bottom: 0px;
}
div#hdrarea {
    display: none;
}
button.button.button--full.button--primary {
    height: 37px !important;
}
.shopconf {
    display: none;
}
.mobile_filter {
    display: block;
}
.desktop_filter {
	display: none !important;
}
li.main_filter_links {
    border-right: none;
}
ul.submenu.filter_submenu {
    position: absolute;
    z-index: 99;
    left: 0;
}
ul.submenu.filter_submenu .submenu li{
	padding: 17px 0 15px -3px;
}
ul.submenu.filter_submenu1 li {
    padding: 10px 50px;
    border: none;
    margin: 0;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
}
ul.submenu.filter_submenu1 li:nth-child(1){
	border-top: 1px solid #e5e5e5;
}
li.filterlinks {
    padding: 0;
}
.header__mobile-content
{
	overflow: scroll !important;
}
.cross-sell li {
    display: block !important;
    position: relative !important;
    width: 45% !important;
    float: left !important;
    margin: 0 auto !important;
    margin: 10px !important;
    left: 3%;
    padding: 5px !important;
	/*min-height:385px;*/
}
.cross-sell li:nth-child(3n+3) {  
  clear:both;
}
.menu--mobile .menu__icon-container {
    right: 10px !important;
    width: 100% !important;
    text-align: right !important;
}
.menu--mobile .icon-minus{
	float: right;
}
.cart-check.mobile {
    display: block !important;
	margin-bottom:20px;
}
.cart-check.desktop{
	display:none !important;
}
}
#shopping-bag .cta-paypal, #peek-content .cta-paypal {
    padding: 5px 0;
}
#shopping-bag .cta {
    padding: 10px 22px 10px;
}
.cta.empty-bag {
    /* background: #494949; */
    /* border-top-color: #494949; */
    color: #303030;
    cursor: default;
}
.twelve, .row .twelve {
    width: 100%;
}
.bag-summary-cta a {
    margin-top: 15px;
}
a.cta {
    display: inline-block;
    font-size: 1em;
    text-decoration: none;
}
.cta-paypal {
    color: #000;
    background: #f9f9f9;
    background: -moz-linear-gradient(top, #f9f9f9 0%, #f3f3f3 50%, #e7e7e7 51%, #e8e8e8 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f9f9f9), color-stop(50%,#f3f3f3), color-stop(51%,#e7e7e7), color-stop(100%,#e8e8e8));
    background: -webkit-linear-gradient(top, #f9f9f9 0%,#f3f3f3 50%,#e7e7e7 51%,#e8e8e8 100%);
    background: -o-linear-gradient(top, #f9f9f9 0%,#f3f3f3 50%,#e7e7e7 51%,#e8e8e8 100%);
    background: -ms-linear-gradient(top, #f9f9f9 0%,#f3f3f3 50%,#e7e7e7 51%,#e8e8e8 100%);
    background: linear-gradient(to bottom, #f9f9f9 0%,#f3f3f3 50%,#e7e7e7 51%,#e8e8e8 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9', endColorstr='#e8e8e8',GradientType=0 );
    border-top: 1px solid #e8e8e8;
    padding-bottom: 7px;
}
.cta {
    color: #FFFFFF;
    padding: 8px 19px 14px;
	cursor: pointer !important;
    text-transform: uppercase;
    font-family: 'exp-bold',arial;
    text-align: center;
    line-height: 1.3em;
    height: 38px;
    border-radius: 0.4em;
    border: none;
    font-size: 1em;
    -webkit-appearance: none;
}
.cta-paypal.empty-bag span:first-child {
    color: #303030;
}

.cta-paypal span:first-child {
    color: #000;
    font-size: 10px;
    line-height: 0;
    position: relative;
    top: 3px;
}
.cta-paypal .paypal-two-colors {
    background-position: -428px -309px;
    display: inline-block;
    height: 23px;
    margin-left: 3px;
    text-transform: uppercase;
    width: 78px;
}
input[type="radio"] + label.payment-type, label.payment-type, .payment-type {
    background: url('//serbags.myshopify.com/cdn/shop/t/23/assets/icon-sprite.png?v=100606201869372610911461213945') no-repeat;
    text-indent: -9999px;
    display: block;
    margin-left: 15px;
    margin-right: 0;
}
.related-products {
    padding: 2.25rem 0 2.75rem 0;
}
h3.related-products__title {
    margin: 20px;
    font-size: 1.875rem;
}
.cart__checkout-with input[type="image"] {
    height: 0;
    width: 100%;
    position: relative;
    z-index: 999;
}
span.exp-medium {
    position: relative;
    top: -23px;
    z-index: 0;
	font-size: 10px;
}
.cta-paypal.empty-bag .paypal-two-colors {
	background-position: -428px -341px;
	position: relative;
	top: -27px;
	z-index: 0;
}
.cart__checkout-with{
	margin-top:0;
}
figure.product-item__figure.product-item__figure--alternate-image-loaded {
    width: 271px;
    height: auto;
}
figure.product-item__figure.product-item__figure--alternate-image-loaded img {
    min-height: 200px;
    max-height: 200px;
}
.related-products{
	background:#fff !important;
}

.cross-sell{
	/*width: 95%;*/
	margin:0 auto !important;
}
#cross-sell{float:left;clear:both;width:100%;}
h3.crossselltitle{margin:25px 0; padding-top:25px;}
.cross-sell{overflow:hidden;list-style-type:none;margin:0;padding:0;clear:both;}
.cross-sell *{font-size:11px; font-weight: bold; text-transform: uppercase; text-align:center; padding:0;}
.cross-sell h4{border:none;margin:5px 0 0 0;line-height:1.5;}
.cross-sell div.image{max-width: 100%;height:auto;}
.cross-sell li{display:inline-block;padding: 10px 0 10px 50px;margin:0 !important;position:relative;width: 271px;}
.cross-sell li:last-child{margin-right:0;}
.cross-sell li .image{}
.cross-sell li .image img{margin:0px 0 10px 0px;float:left;width:100%;}
.cross-sell li .producttitle{float:left;padding:5px 0;width:100%;min-height:56px;}
.cross-sell li a{text-decoration:none;font-size:115%;line-height:20px;padding:5px;}
.cross-sell li .money a{font-size:130%;float:left;text-align:center; padding:5px 0;width:100%;}
#abc {
    height: 100%;
    width: 100%;
	display: none;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.7);
    overflow: scroll;
}
#abc1 {
	height: 100%;
    width: 100%;
	display: none;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.7);
    overflow: scroll;
}
img#close {
	position:absolute;
	right:-14px;
	top:-14px;
	cursor:pointer;
}
img#close1 {
	position:absolute;
	right:-14px;
	top:-14px;
	cursor:pointer;
}
div#popupContact {
    position: absolute;
    left: 5%;

    top: 10%;
    margin-left: 0;
  
    z-index: 99999;
    right: 5%;
    border: 10PX;
    opacity: 1;
    background: #fff;
    margin-Bottom: 5%;
}
li.menu__item.no_before a:before{
	display:none;
}
.tooltip {
	display: block;
  
} 
span#el {
    position: relative;
    top: 10px;
    padding: 0;
    background: none;
	cursor:pointer;
}
span#el img {
    position: relative;
    top: -5px;
}
li.menu__item.no_before:hover{
	color:#c80000;
}
.share-buttons {
 padding-top: 30px;
}
.button--primary, .spr-summary-actions-newreview, .spr-button-primary{
	background: transparent;
}
.container.popup {
    max-width: 99%;
}
.cart-check.mobile {
    display: none;
}
.cart-check.desktop{
	display:block;
}
@media screen and (max-width: 500px){
.header__logo a.header__link {
	text-align:center;
	height:auto;
	}
}
@media screen and (min-device-width: 768px) and (max-device-width: 1100px) { 
.tooltip {
	display: none !important;
}
.cross-sell li {
    width: 250px !important;
}	
.header__mobile-nav {
    display: block !important;
}
.menu--desktop{
	display:none !important;
}
div#hdrarea {
    display:none !important;
}
.desktop-show {
    display:none !important;
}
.header__actions {
    display:none !important;
}
.header__logo{
	display: block !important;
	margin: 0 auto !important;
	text-align: center !important;
	float: none;
	padding-left: 60px;
}
.menu--mobile .menu__icon-container {
    right: 10px !important;
    width: 100% !important;
    text-align: right !important;
}
.menu--mobile .icon-minus{
	float: right;
}
}
@media screen and (max-width: 480px) {

input.footer__input {
    box-sizing: border-box;
}

section.footer__module {
    box-sizing: border-box !Important;
    max-width: 100%;
}

img.header__logo-image {
    max-height: 100%;
    margin-left: 66px;
}

.header__logo a.header__link {
    text-align: center;
	height:auto;
}
.marketing-module__column {
    width: 100%;
    display: block;
    margin: 0px !Important;
}
.template-product .h5.menswearev {
    display: none;
}
}
@media (max-width: 767px){
.container.mobile_content_blog {
	display: none !important;
}
.powerTip {
	display: none;
}
section.index-module.extra_remove_mobile_text.index-module__free-text-2 {
    display: none;
}
.hide_mobile{
	display: none;
}
.mobile_hide_text_footer{
	display: none;
}
.collection_hide_text{
	display:none;
}
.product__inventory, .product__reviews-badge{
	margin:0 auto;
	width:150px;
}
.product__tab-selector.form__select select {
    width: 100%;
    height: 40px;
	-webkit-appearance: none;
	-moz-appearance: none;  
}
.template-product h5.menswearev {
    display: none;
}
}